import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"
import { graphql } from "gatsby"

const WhatsNewDesktop = ({ data }) => (
  <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO
      postTitle="What's new in BlueMail for Desktop"
      postDescription="With support for Windows, Mac and Linux, the new BlueMail appp introduces a host of new email focused features that enhance productivity and collaboration for everyone."      
      postImage="/img/OG/og_image-whats-new-desktop.png" 
      postURL="whats-new-desktop"
      postSEO
    />
    <div className="container whats-new-desktop pt-120 pb-100">
      <iframe
        width="100%"
        height="660px"
        marginTop="100px"
        src="https://www.youtube.com/embed/CYxVDaklq54?rel=0&autoplay=0&controls=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&noCookie=true&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1&widget_referrer=https%3A%2F%2Fbluemail.me"
        // srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}img{border-radius: 20px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/oZxSx4uto_0?rel=0&autoplay=0&controls=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&noCookie=true&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1&widget_referrer=https%3A%2F%2Fbluemail.me><img src=/img/whats-new/whats-new.webp alt="What's new on desktop"><span>▶</span></a>`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
        allowFullScreen
        title="BlueMail - What's new in BlueMail for Desktop"
      ></iframe>
    </div>
    <Footer />
  </Layout>
)

export default WhatsNewDesktop

export const query = graphql`
  query WhatsNewDesktopPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
